<template>
    <v-row>
        <v-col>
            <v-card class="pa-4">
                <v-card-title><a @click="goToList">{{ site.title }}</a>&nbsp;-&nbsp;{{ entity.title }}</v-card-title>
                <v-card-text>
                    <EntityTabLinks />
                    <v-row class="pl-3 pb-3" />
                    <v-row form class="form-group">
                        <v-col sm="3">Название</v-col>
                        <v-col sm="9">{{ entity.title }}</v-col>
                    </v-row>
                    <v-row form class="form-group">
                        <v-col sm="3">Телефон</v-col>
                        <v-col sm="9">{{ entity.phone }}</v-col>
                    </v-row>
                    <v-row form class="form-group">
                        <v-col sm="3">Адрес</v-col>
                        <v-col sm="9" v-html="htmlFormatted(entity.address)"></v-col>
                    </v-row>
                    <!-- <v-row>
                        <v-col sm="3">Зона доставки</v-col>
                        <v-col sm="9">
                            <div class="entity_detail__map__wrapper" v-if="entity.coordinates">
                                <yandexMap 
                                    ref="ymap"
                                    class="entity_detail__map"
                                    :coords="entity.coordinates"
                                    :controls="['zoomControl']"
                                    :zoom="mapZoom"
                                >
                                    <template v-if="entity.coordinates">
                                        <ymapMarker 
                                            marker-id="address"
                                            marker-type="Placemark"
                                            :coords="entity.coordinates"
                                        />
                                        <ymapMarker 
                                            marker-id="delivery-radius"
                                            marker-type="Circle"
                                            circle-radius="3000"
                                            :coords="entity.coordinates"
                                        />
                                    </template>
                                </yandexMap>
                            </div>
                        </v-col>
                    </v-row> -->
                    <v-row form class="form-group">
                        <v-col sm="3">Часы работы</v-col>
                        <v-col sm="9" v-html="htmlFormatted(entity.work_time)"></v-col>
                    </v-row>
                    <v-row form class="form-group">
                        <v-col sm="3">Активен</v-col>
                        <v-col sm="9">
                            <span :class="`true-false-label ${getBadge(entity.enabled)}`">
                                &nbsp;{{entity.enabled ? 'Да' : 'Нет' }}&nbsp;
                            </span>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="pa-4 d-flex justify-space-between">
                    <div>
                        <v-btn
                            color="light"
                            class="mr-3"
                            @click="goToList"
                        >К списку</v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    import store from '../../store';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { mapState } from 'vuex';
    import { yandexMap, ymapMarker } from 'vue-yandex-maps';
    import EntityTabLinks from '../../components/sites/EntityTabLinks';

    import { imageSrc, htmlFormatted } from '../../helpers';

    export default {
        name: 'EntityDetail',

        components: {
            EntityTabLinks,
            yandexMap, 
            ymapMarker
        },

        data() {
            return {
                imageSrc,
                htmlFormatted,
                mapZoom: 12,
                deleteModal: false,
            };
        },

        computed: {
            ...mapState('auth', ['user']),
            ...mapState('sites', {
                site: state => state.entity
            }),
            ...mapState('entities', {
                entity: state => state.entity
            }),
            ...mapState('siteTypes', {
                siteTypes: state => state.entities
            }),
            isAdmin() {
                return this.user.admin;
            },
            siteType() {
                const type = this.siteTypes.find(item => item.id === this.entity.site_type_id);
                return type ? type.title : '';
            }
        },

        async mounted() {
            const { site_id, id } = this.$route.params;
            await store.dispatch('siteTypes/fetch');
            if(site_id) {
                await store.dispatch('sites/get', { id: site_id });
            }
            if(site_id && id) {
                await store.dispatch('entities/get', { site_id, id });
            }
        },

        methods: {
            getBadge (status) {
                return status ? 'success' : 'error';
            },
            goToList() {
                const { site_id } = this.$route.params;
                if(site_id) {
                    this.$router.push(`/sites/${site_id}/entities`);
                } else {
                    this.$router.push('/sites');
                }
            },
        },
    };
</script>

<style lang="scss">
    .entity_detail {
        &__map {
            width: 100%;
            height: 400px;

            &__wrapper {
                width: 100%;
                height: 400px;
                border-radius: 4px;
                overflow: hidden;
            }
        }
    }
</style>